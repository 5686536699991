function Logo() {
  return (
    <img
      width={200}
      src="https://www.circle.com/hubfs/Circle%20logo%202020/2000x1000.gif"
      alt="Circle Logo"
    />
  );
}

export default Logo;